import React, { Fragment } from "react"
import styled from "styled-components"
import Text from "../Blocks/Text"
import { GatsbyImage } from "gatsby-plugin-image"
import GetNotifiedForm from "../Forms/GetNotifiedForm"
import colors from "../../styles/colors"

const BlockContainer = styled.div`
  max-width: 750px;
  margin: auto;
`

const Heading = styled.h1`
  color: ${colors.royalBlue};
  margin-bottom: 2rem;
  line-height: 1.104;
  text-align: center;
`

const TextBlock = styled.div`
  margin: 2.5rem auto;
  text-align: center;
`

const SignupBlock = ({ blocks }) => {
  const block = blocks[0]
  const { blockHeading, image, textBlocks, blockFooterText } = block

  return (
    <BlockContainer>
      <Heading>{blockHeading}</Heading>
      <GatsbyImage
        image={image && image.gatsbyImageData}
        alt={image.description || ""}
      />
      {textBlocks && (
        <TextBlock>
          {textBlocks.map((textBlock, index) => {
            const { heading, content } = textBlock
            return (
              <Fragment key={index}>
                {heading ? <h3>{heading}</h3> : null}
                {content ? <Text content={content} /> : null}
              </Fragment>
            )
          })}
        </TextBlock>
      )}
      <GetNotifiedForm signup />
      {blockFooterText && (
        <TextBlock>
          <Text content={blockFooterText} />
        </TextBlock>
      )}
    </BlockContainer>
  )
}

export default SignupBlock
